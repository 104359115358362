import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  public jwttoken: any = '';
  public secret: any = '';
  public fullname: any = '';
  public firstname: any = '';
  public lastname: any = '';
  public status: any = '';
  public type: any = '';
  public email: any = '';
  public _id: any = '';
  public lastlogindate: any = '';
  public lastlogincity: any = '';
  public lastloginregion: any = '';
  public contractsign: any = '';
  public parentid: any = '';


  constructor(public cookieservice: CookieService) {
    if (this.cookieservice.check('jwtToken') && typeof (this.cookieservice.get('jwtToken')) != 'undefined') {
      this.jwttoken = this.cookieservice.get('jwtToken');
    }
    this.secret = localStorage.getItem('secret');
    if (this.cookieservice.check('firstname') && this.cookieservice.get('firstname') != null) {
      // console.log(this.cookieservice.get('firstname'),'++++')
      this.fullname = JSON.parse(this.cookieservice.get('firstname'));
      this.firstname = JSON.parse(this.cookieservice.get('firstname'));
    }
    if (this.cookieservice.check('lastname') && typeof (this.cookieservice.get('lastname')) != 'undefined') {
      this.fullname = this.fullname + ' ' + JSON.parse(this.cookieservice.get('lastname'));
      this.lastname = JSON.parse(this.cookieservice.get('lastname'));
    }
    if (this.cookieservice.check('status') && typeof (this.cookieservice.get('status')) != 'undefined') {
      this.status = JSON.parse(this.cookieservice.get('status'));
    }
    if (this.cookieservice.check('type') && typeof (this.cookieservice.get('type')) != 'undefined') {
      this.type = JSON.parse(this.cookieservice.get('type'));
    }
    if (this.cookieservice.check('email') && typeof (this.cookieservice.get('email')) != 'undefined') {
      this.email = JSON.parse(this.cookieservice.get('email'));
    }
    if (this.cookieservice.check('_id') && typeof (this.cookieservice.get('_id')) != 'undefined') {
      this._id = JSON.parse(this.cookieservice.get('_id'));
    }
    if (this.cookieservice.check('parentid') && typeof (this.cookieservice.get('parentid')) != 'undefined' && this.cookieservice.get('parentid') != 'undefined') {
      this.parentid = JSON.parse(this.cookieservice.get('parentid'));
    }


    if (this.cookieservice.check('lastlogindate') && typeof (this.cookieservice.get('lastlogindate')) != 'undefined') {
      this.lastlogindate = JSON.parse(this.cookieservice.get('lastlogindate'));
    }
    if (this.cookieservice.check('lastlogincity') && typeof (this.cookieservice.get('lastlogincity')) != 'undefined') {
      this.lastlogincity = JSON.parse(this.cookieservice.get('lastlogincity'));
    }
    if (this.cookieservice.check('lastloginregion') && typeof (this.cookieservice.get('lastloginregion')) != 'undefined') {
      this.lastloginregion = JSON.parse(this.cookieservice.get('lastloginregion'));
    }
    if (this.cookieservice.check('contract_sign') && typeof (this.cookieservice.get('contract_sign')) != 'undefined') {
      this.contractsign = JSON.parse(this.cookieservice.get('contract_sign'));
    }

  }


  fb(e, url: any, products: any) {
    // console.log(e, url)
    // userservice?._id
    // let url = 'www.google.com';
    e.preventDefault();
    var facebookWindow = window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + url + this._id.replace(/^\s+|\s+$/gm, '') + '/' + products.replace(/^\s+|\s+$/gm, ''),
      'facebook-popup',
      "width=800,height=500,left=200,top=5,scrollbars,toolbar=0,resizable"
    );
    if (facebookWindow.focus) {
      facebookWindow.focus();
    }
    return false;

  }
  tw(url: any, name: any, products: any) {
    var twitterWindow = window.open('https://twitter.com/intent/tweet?source=tweetbutton&text=' + name + '&url=' + url + this._id.replace(/^\s+|\s+$/gm, '') + '/' + products.replace(/^\s+|\s+$/gm, ''),
      "popupwindow",
      "width=800,height=500,left=200,top=5,scrollbars,toolbar=0,resizable");
    if (twitterWindow.focus) {
      twitterWindow.focus();
    }
    return false;
  }
  linkedin(url: any, name: any, products: any) {
    var linkedinWindow = window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url + this._id.replace(/^\s+|\s+$/gm, '') + '/' + products.replace(/^\s+|\s+$/gm, '') + '&title=' + name + '&summary=' + name + '&source=' + name,
      "popupwindow",
      "width=800,height=500,left=200,top=5,scrollbars,toolbar=0,resizable"); if (linkedinWindow.focus) {
        linkedinWindow.focus();
      }
    return false;
  }


  cookieset(name: string, value: any) {
    this.cookieservice.set(name, value, undefined, '/')
  }
  cookiedelete(name: string) {
    this.cookieservice.delete(name)
  }
  cookiedeleteall() {
    this.cookieservice.deleteAll()
  }
}
