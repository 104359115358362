import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-backend-footer',
  templateUrl: './backend-footer.component.html',
  styleUrls: ['./backend-footer.component.css']
})
export class BackendFooterComponent implements OnInit {
  //Scrolltotop true/false
  windowScrolled: boolean;

  //Copyright Year
  public htmlData: any = {};
  constructor(public router: Router, public activatedRoute: ActivatedRoute,public dialog: MatDialog) {
    //Copyright Year
    this.htmlData["year"] = new Date().getFullYear();
   }
  ngOnInit() {
    //Scrolltotop behavior
    this.router.events.subscribe(() =>
          window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
          })
      );
  }  
  //Scrolltotop scroll
  @HostListener("window:scroll", [])
  onWindowScroll() {
      if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
          this.windowScrolled = true;
      }
      else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
          this.windowScrolled = false;
      }
  }  
  scrollToTop() {
      (function smoothscroll() {  
          var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;  
          if (currentScroll > 0) {
              window.requestAnimationFrame(smoothscroll);
              window.scrollTo(0, currentScroll - (currentScroll / 8));
          }
        })
    ();
  }

}
