import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaserviceService } from 'src/app/metaservice/metaservice.service';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from "src/app/service/api.service";
import { HttpService } from "../service/http.service";
import { UserserviceService } from 'src/app/service/userservice.service';
import { HttpClient } from '@angular/common/http';

import { ImagelodeserviceService } from "../service/imagelodeservice.service";
import { Subscription } from 'rxjs';

export interface htmlDialog {
  details: any;
  groupid: any;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  //dataApplyNowForm and form submit Declaration
  dataApplyNowForm: FormGroup;
  public successModal: any = false;
  name: string;
  email: string;
  phone: string;
  message: string;
  title: any;
  //Declaration subject object
  public page: any;
  public href:any;
  public pageRouter:any;
  // public pageExternalLink: any;

  //subject array of object
  subjects = [
    {
      name: "CENTRAL DOCTRINE",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image1.jpg",
      pageRouter: "",
      content:"Our school’s central doctrine outlines the Theurgic ritual praxis of our school. If you are interested in the Inner mysteries of our school and our activities and future plans this is the best place to start. We cover the science, ritual technology and overall methods of our work.  We syncretize ancient practices of Neoplatonic Theurgy with the breakthroughs in psychology, quantum biology, quantum mechanics and string theory in a new vision of ritual work.  There are many important differences as well as similarities to other mystery schools and to learn what sets our methods apart please take the time to review our central doctrine."
    },
    {
      name: "Layman Symbolism",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image2.jpg",
      pageRouter: "",
      content:"Our school Layman is a Cosmic map of the different Archetypes, gods, goddesses and ritual symbolism central to the Theurgic practices of our school. We suggest you start with the Central Doctrine to get a definition of the different aspects and language you will encounter in review of the school Layman first. Once you have a grasp of these initial ideas, a review of the Layman symbolism will give you a deep narrative and doorway into the Cosmic ontology presented in this grand symbolic persona of the infinite.  The school Layman is a single map of the different uses of liturgy, both east, west and modern, giving you a vision of our work."
    },
    {
      name: "Mystery school",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image3.jpg",
      pageRouter: "",
      content:"The Inner Mystery School of the Divine Infinite Being is both a monthly membership as well as a set of Theurgic rituals set over a period of 4 years. There are four grades in our school, although not set up as other schools with a rise in the grades.  Our grades are specific to accomplishing the work that has been prescribed, as we believe the level of attainment is between one and the Cosmos and not a decision of any superior. Members are given access to our member center where they gain access to video training and materials for the rituals performed over the course of each of the 4 year period.  Click to learn more about how to apply."
    },

    {
      name: "Divine Arcana Tarot",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image4.jpg",
      pageRouter: "divine-arcana-tarot",
      content:"The Divine Arcana Tarot is a 4 year collaboration between our teacher, Beto Paredes, the occult artist Raju Sarkar and the assistance of different philosophers and religious practitioners of both eastern and western systems. We offer you the opportunity to engage the symbolic archetypal language of our Tarot here on our website for free. We offer the purchase of our deck, as well as training for professional readers in our school. We follow the format of ROTA or the Astrological Wheel of the Tarot in our work as other mystery schools, but we have included archetypal language in their meaning from both western and eastern sources."
    },
    {
      name: "Rota the wheel",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image5.jpg",
      pageRouter: "rota-the-wheel",
      content:"ROTA the Wheel is a symbolic encyclopedia of Astrological archetypes built upon the framework of the Tarot system of ritual and divination. This alchemy of archetypes is the central diagram that we use for the ritual Theurgy of our school.  Other schools use this framework as well, such as the Golden Dawn and Thelemic schools, but for us it is the central focus of all of our ritual praxis and visionary work.  Understanding how this symbolism works together in the Wheel (ROTA) will unlock the archetypal language of our Divine Arcana Tarot as well as other related systems such as the Rider Waite and the Thelemic Thoth Tarot. Our 4 year mystery school brings to life these predicates of ROTA."
    },
    {
      name: "Tarot Typology",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image6.jpg",
      pageRouter: "",
      content:"The school of the Divine Infinite Being has incorporated Jungian themes into much of what we employ.  This you can see most directly in our central doctrine. Jung was a master of Typology and developed a system of psychological types that has grown into one of the most popular systems to date.  The Meyers Briggs foundation, based on the framework of Jung’s work, developed 16 archetypes that have found wide use in their personality test globally.  These 16 archetypes match the 16 court cards of the Tarot with such a precision there has been much literature written on the subject. Learn more about this here."
    },

    {
      name: "Study Chapters ",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image7.jpg",
      pageRouter: "",
      content:"The School of the Divine Infinite Being, along with the outer school of Timothy Desmond. <a target='_blank' href='https://www.psycheandsingularity.com'>https://www.psycheandsingularity.com </a>  will be creating study chapters in cities across the world. We currently have them going in Salt Lake City, UT and will be opening more. We share our plans in the Central Doctrine, but you can review our format and study guide here on this page.  We are reopening the Platonic Academies at meet ups across the world and invite you to participate in these study groups if you are local enough to join. We cover a myriad of subjects and reading materials, of high interest to those practicing the Divine Theurgy."
    },
    {
      name: "Calendar of Events+6",
      image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image8.jpg",
      pageRouter: "",
      content:"As the school grows we will be holding events in the United States, Europe and India.  We have initiations every Solstice and Equinox which are done in person for those that can make it to us and over Zoom for those that can not.  Every summer we will have a gathering on the Summer Solstice and a 7 day intensive every year in September. We will be listing all of the study chapters and their events as well as all the school events on this page, so be sure to bookmark it and come back often to see what we are up to!  We have current gatherings in Salt Lake City UT where we plan to open the first temple of the Divine Infinite Being."
    },
    // {
    //   name: "Neoplatonic Theurgy",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home-page-as-image1.jpg",
    //   pageRouter: "",
    // },

    
    // {
    //   name: "Jungian Psychology",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image15.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "The Celebratory Wheel",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image8.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Re-imaging the Family",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image9.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Teaching Children",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image10.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Teaching Adolescents",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image11.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Teaching Young Adults",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image12.png",
    //   pageRouter: "",
    // },



    // {
    //   name: "Dual Aspect Monism",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image16.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Mystic Christianity ",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/Mystic%2B_christianity_new.webp",
    //   pageRouter: "",
    // },
    // {
    //   name: "Gods and Goddesses",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image18.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Chi Gong",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image19.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Sacred Scriptures",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image20.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Stoic Philosophy",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/sse_image21.png",
    //   pageRouter: "",
    // },
    // {
    //   name: "Sacred Geometry & Cosmometry",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/SacredGeometryCosmometry.webp",
    //   pageRouter: "sacred-geometry-and-cosmometry",
    // },
    // {
    //   name: "Thoth Tarot Basics",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/ThelemicTarotMagick.webp",
    //   pageRouter: "thoth-tarot-basics",
    // },
    // {
    //   name: "Bioelectricity and Qi",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/TAOChiCultivation.webp",
    //   pageRouter: "bioelectricity-and-qi",
    // },
    // {
    //   name: "Tantra and the Esoteric",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/KundaliniTantra.webp",
    //   pageRouter: "tantra-and-the-esoteric",
    // },
    // {
    //   name: "Tantric Buddhism",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/TantricBuddhistLiberation.webp",
    //   pageRouter: "tantric-buddhism",
    // },
    // {
    //   name: "Morphogenic Fields",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/MorphicResonance.webp",
    //   pageRouter: "morphogenic-fields",
    // },
    // {
    //   name: "Hermetic Philosophy",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/HermeticPhilosophy.webp",
    //   pageRouter: "hermetic-philosophy",
    // },
    // {
    //   name: "Alchemy",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/Alchemy.webp",
    //   pageRouter: "alchemy",
    // },
    // {
    //   name: "Psychedelics and Religion",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/PsychedelicsandReligion.webp",
    //   pageRouter: "psychedelics-and-religion",
    // },

    // {
    //   name: "Electromagnetic Force",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/ElectromagneticForce.webp",     
    //   pageRouter: "",
    // },
    // {
    //   name: "Experience As Art",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/ExperienceAsArt.webp",     
    //   pageRouter: "",
    // },
    // {
    //   name: "Transpersonal Magick",
    //   image: "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/TranspersonalMagick.webp",     
    //   pageRouter: "",
    // },
  ]
  public brimages: any = [
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homeblockonebg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homeblockfourbg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homeblockfivebg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homebottombannerbg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homebottombannerbg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homeblockfourblock2bg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/home/homeblockfourblock3bg.webp",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/footerbg.png",
    "https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/logobanner.webp",
  ];

  constructor(public ActivatedRoute: ActivatedRoute,private metaservice: MetaserviceService, public dialog: MatDialog, private router: Router, public cookies: CookieService, public imageservices: ImagelodeserviceService) {

    //MetaService section
    const data: object = {
      title: 'SCHOOL OF THE DIVINE INFINITE BEING BASIS OF PRACTICE',
      keywords: 'Orphism,  Pagan Personification, Polytheistic Narrative',
      description: 'We train Astrologers, Tarot readers and offers a Mystery School for families, children, and adults. We follow the breakthrough new spiritual science of Mandala YogaMaya, Jungian Psychology and the Celebratory wheel of Astrology through the Archetypes of the Tarot.',
      og_description: 'We train Astrologers, Tarot readers and offers a Mystery School for families, children, and adults. We follow the breakthrough new spiritual science of Mandala YogaMaya, Jungian Psychology and the Celebratory wheel of Astrology through the Archetypes of the Tarot.',
      og_url: 'https://transcendentpagan.com/',
      og_type: 'website',
      og_image: 'https://all-frontend-assets.s3.amazonaws.com/transcendentpagan/assets/images/og_image.jpg',
    };
    this.metaservice.setmeta(data);
    this.imageservices.setimageloaderflag(true);
    this.imageservices.startloadscheck(this.brimages);
    // brimages 
  }

  ngOnInit() {
    let servicedata: any = this.imageservices.imageFunc("data");
    console.log("servicedata", servicedata);

  }

  //Router link for valid subject && comingSoon for empty route

  doSubjectsModal(val: any) {    
    this.page = val.pageRouter;
    // this.pageExternalLink = val.navLinks;
    if (this.page != null && this.page != undefined && this.page != '') {
      this.router.navigate(['/' + this.page]); // navigate to routerLink page
      // window.open('/'+ this.page, "_blank"); // target to black page
      // console.log("if part",this.page);
    }
    // else if (this.pageExternalLink != null && this.pageExternalLink != undefined && this.pageExternalLink != '')
    // {
    //   window.open(this.pageExternalLink, "_blank"); // navigate to ExternalLink page
    //   // console.log("else if part",this.pageExternalLink);
    // }
    else {
      const dialogRef = this.dialog.open(comingSoonModalComponent, {
        panelClass: 'successModal'
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe();
    }
  }

 

  //Coming Soon Modal with subject loop
  doComingSoon() {
    const dialogRef = this.dialog.open(comingSoonModalComponent, {
      panelClass: 'successModal'
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe();
  }

  //ExternalUrl Redirection
  goToSpiritconUrl(): void {
    window.open("https://spiritconvention.com/", "_blank");
  }

  //Zoom Logo Modal
  doZoomLogo() {
    const dialogRef = this.dialog.open(zoomLogoModalComponent, {
      panelClass: ['successModal', 'logoZoom']
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  //Apply Now Modal
  applyNowModal(title: any) {
    const dialogRef = this.dialog.open(applNowModalComponent, {
      //object assign
      data: { title: title },
      panelClass: ['successModal', 'applyNowDialog']
    });
    dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);  
    });
  }
}

//comingSoon Modal
@Component({
  selector: 'app-comingSoon',
  templateUrl: '../common/comingsoon.html',
  styleUrls: ['./home.component.css']
})
export class comingSoonModalComponent { }

//doZoomLogo Modal
@Component({
  selector: 'app-ZoomLogo',
  templateUrl: '../common/doZoomLogo.html',
  styleUrls: ['./home.component.css']
})
export class zoomLogoModalComponent { }

//applyNow Modal
@Component({
  selector: 'app-applyNowModal',
  templateUrl: '../common/applyNow.html',
  styleUrls: ['./home.component.css']
})
export class applNowModalComponent {
  //dataApplyNowForm and form submit Declaration

  //  dataApplyNowForm: FormGroup;
  //  name:string;
  // email:string;
  // phone:string;
  // message:string;
  // public successModal: any = false; 

  // applyNowFormSubmit:string;
  // amitava

  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",

        ],
      },
      {
        name: "links",
        items: [
          "Link",

        ],
      },

      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",

        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",

        ],
      },


      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;

  temtdata: any = '';
  // formdata
  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public resolveval: any;
  public certificationname: any = "";

  constructor(
    public dialogRef: MatDialogRef<applNowModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, public dialog: MatDialog,
    public apiservice: ApiService, public http: HttpClient, public activatedroute: ActivatedRoute, public cookies: CookieService, public router: Router, public userservices: UserserviceService, public snackBar: MatSnackBar, public apiservicenew: HttpService) {

    console.log("modal=======", data.title);
    this.certificationname = data.title;
    // this.dataApplyNowForm = this.fb.group({
    //   name:['', [Validators.required,Validators.minLength(3)]],
    //   email: ['', Validators.compose([Validators.required, Validators.pattern(/^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/)])],
    //   phone:['', [ Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10), Validators.maxLength(10)]],
    //   message:['',Validators.required],
    // })

    // this.apiservice.getState().subscribe((res: any) => {
    //   for (const i in res) {
    //     if (Object.prototype.hasOwnProperty.call(res, i)) {
    //       this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
    //     }
    //   }
    // });




    this.formdata = {
      successmessage: 'Added Successfully !!',
      redirectpath: '/home',
      submitactive: true, // optional, default true
      submittext: 'Submit',
      // resettext: 'Reset',
      // canceltext: 'Cancel',
      apiUrl: this.apiservice.api_url,
      // endpoint: 'api/adddata123',
      // jwttoken: this.cookies.get('jwtToken'),
      jwttoken: this.userservices.jwttoken,
      cancelroute: '/home',

      fields: [
        {
          label: 'Name',
          name: 'name',
          value: '',
          type: 'text',
          validations: [{ rule: 'required', message: 'Name is Required' }],
        },
        {
          label: 'Email',
          name: 'email',
          type: 'email',
          hint: '',
          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },
        {
          label: 'Phone No',
          name: 'phone',
          value: '',
          type: 'number',
          validations: [
            { rule: 'required', message: 'Phone No is Required' },
            {
              rule: 'minLength',
              value: 10,
              message: 'The Phone Number must be 10 digits',
            },
            {
              rule: 'maxLength',
              value: 15,
              message: 'Please enter the valid number',
            },
          ],
        },
        {
          label: 'Message',
          name: 'message',
          value: '',
          type: 'textarea',
          validations: [{ rule: 'required', message: 'Message is Required' }],
        },
        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'admin',
        },
      ],
    };

  }

  //applyNowSubmit Submission
  listenFormFieldChange(val: any) {
    if (val.field == "fromsubmit" && val.fieldval == "success") {
      if (val.fromval.status == true) {
        val.fromval.status = parseInt("1");
      } else {
        val.fromval.status = parseInt("0");
      }
      let data: any = {
        "data": val.fromval,
        "collection": "certification"
      }
      delete data.data.status;
      data.data['certification_name'] = this.certificationname;
      this.apiservicenew.httpViaPost("api/adddata", data).subscribe(response => {
        if (response.status == "success") {
          this.snackBar.open("Added Succesfully", 'Ok', {
            duration: 2000,
          });
          this.dialogRef.close();
          this.router.navigateByUrl("home");
        } else {
        }
      });
    }
  }
  // inputUntouched(val: any) {
  //   this.dataApplyNowForm.controls[val].markAsUntouched();
  // }
}

//applyNowSubmit Modal
@Component({
  selector: 'app-applyNowSubmitModal',
  templateUrl: '../common/success.html',
  styleUrls: ['./home.component.css']
})
export class applyNowSubmitComponent {
  constructor() {
  }
}