import { Compiler, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-backend-header',
  templateUrl: './backend-header.component.html',
  styleUrls: ['./backend-header.component.css']
})
export class BackendHeaderComponent implements OnInit {
  public type: any = '';
  public returntoadminflag = false;

  navbarOpen = false;
  status: boolean = false;

  constructor(public cookieservice: CookieService, public compiler: Compiler, public router: Router) {
    if(this.cookieservice.check('type')){
      this.type = JSON.parse(this.cookieservice.get('type'));
      console.log("type==",this.type);
    };

    if (this.cookieservice.check("original_user")) {
      this.returntoadminflag = true;
    } else {
      this.returntoadminflag = false;

    }

  }

  ngOnInit() {
  }


// ####################### Menu Toogle ##############


  clickEvent(){
      this.status = !this.status;
  }
//######################################################



  logout() {
    this.cookieservice.deleteAll(); // cookie service delete
    this.cookieservice.deleteAll('/');
    this.compiler.clearCache();
    localStorage.clear(); // local storage clear
    setTimeout(() => {
      this.compiler.clearCache(); // cache clear
      window.location.href = 'home';
    }, 500);
    console.log("logout successful")
  };
  myaccount() {
    this.router.navigateByUrl("/my-account/" + JSON.parse(this.cookieservice.get("_id"))); // get user account details
  };

  returnToOriginalUser() {
    // let last_url = JSON.parse(this.cookieservice.get("last_url"));

      if (this.cookieservice.check("original_user")) {
        let original_userdata = JSON.parse(this.cookieservice.get("original_user"));
        let secret = this.cookieservice.get("secret");
        let jwtToken = this.cookieservice.get("jwtToken");
        console.log("original_userdata=",original_userdata);
        console.log("secret in backend headr page==",secret);
        console.log("jwtToken in backend headr page==",jwtToken);
        let last_url = this.cookieservice.get("last_url")
        // console.log("last_url==",last_url);

        this.cookieservice.deleteAll()
        this.cookieservice.deleteAll("/");

        setTimeout(() => {
          for (let [keys, values] of Object.entries(original_userdata)) {
            if((keys != "secret") && (keys!= "jwtToken") ){
              this.cookieservice.set(keys, JSON.parse(JSON.stringify(values)));
              localStorage.setItem(keys, JSON.parse(JSON.stringify(values)));
            }
        }
        this.cookieservice.set('secret',secret);
        this.cookieservice.set('jwtToken',jwtToken);
        }, 500);

        setTimeout(() => {
          let new_cookie=this.cookieservice.getAll()
        console.log("new_cookie==",new_cookie);
        }, 600);


        setTimeout(() => {
          // let currentUrl = last_url;
          console.log("router=",this.router);

          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // console.log("router.onSameUrlNavigation=",this.router.onSameUrlNavigation);
          this.router.onSameUrlNavigation = "reload";
          this.router.navigate([last_url]);

        }, 800);

      }

}
}
