import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {switchMap, map, takeWhile, catchError} from 'rxjs/operators';
// import { ObservableService } from "./observable.service";
@Injectable({
  providedIn: 'root'
})
export class HttpService {
 //public api_url = environment['api_url'];
 public apiBaseUrl = environment.api_url;
 public jwtToken = this.cookieService.get('jwtToken');
 public fileimgsslurl: any;
 fileservername: any = [];
 serverUrl: any;
 addendpointUrl: any;
 uploadEndpointUrl: any; // souresh
 updateendpointUrl: any;
 deletesingle_endpointUrl: any;
 updatestatus_single_endpointUrl: any;
 deletemultiple_endpointUrl: any;
 updatestatus_multiple_endpointUrl: any;
 getdata_endpointUrl: any;
 public invalidApi: any;
 public tokenVal: any;
 public getObservableSetData:any;

 constructor(private _http: HttpClient, private cookieService: CookieService, public router: Router, public activatedRoute: ActivatedRoute, private _snackBar: MatSnackBar) {
  
 }

 isTokenExpired() {
 }

 /* read site setting data */
 public getSiteSettingData(url): Observable<any> {
   return this._http.get(url);
 }

 public getJsonData(url): Observable<any> {
   return this._http.get(url);
 }

 customRequest(requestdata: any, endpoint: any, url: any) {
  if (endpoint=="translet-language") {
    return this._http.get(url);
  }else{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.cookieService.get('jwtToken')
      })
    };
  // console.log("sourav",url,endpoint);
  const result = this._http.post(url + endpoint, JSON.stringify(requestdata), httpOptions).pipe(catchError((error) => {
    this.openSnackBar();
    return throwError(error);
  }), map(response => response));
  return result;
 }

}



getDataForResolve(requestdata: any) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  // console.log(requestdata)

  const result = this._http.post(this.apiBaseUrl + requestdata.endpoint, JSON.stringify(requestdata.requestcondition), httpOptions).pipe(catchError((error) => {
    this.openSnackBar();
    return throwError(error);
  }), map(response => response));
  return result;
}

getDataformApi(endpoint: any, requestdata: any) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.cookieService.get('jwtToken')
    })
  };
  const result = this._http.post(this.apiBaseUrl + endpoint, JSON.stringify(requestdata), httpOptions).pipe(catchError((error) => {
    this.openSnackBar();
    return throwError(error);
  }), map(response => response));
  return result;
}
httpViaPost(endpoint, jsonData): Observable<any> {
// console.log("{{}{}{",jsonData);
  /* set common header */
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };
  return this._http.post(this.apiBaseUrl + endpoint, jsonData, httpOptions);
}

openSnackBar() {
  this._snackBar.open('Something Went Wrong ,Try Again!!', 'ok', {
    duration: 6000,
  });
}

}
