import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topcontact',
  templateUrl: './topcontact.component.html',
  styleUrls: ['./topcontact.component.css']
})
export class TopcontactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
