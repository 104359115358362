import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';





import { ApiService } from "src/app/service/api.service";
import { HttpService } from "../../service/http.service";
import { UserserviceService } from 'src/app/service/userservice.service';

import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatMenuTrigger } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';



export interface DialogData {
  // data: any;
  // message: any;
  // secret: any;
  // token: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {  

  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @ViewChild(MatMenuTrigger, { static: true }) 
  trigger: MatMenuTrigger;

  //Menu Openclose Declaration
  navbarOpen = false;
  topPosToStartShowing = 100;

  //SubMenu Openclose Declaration
  isExpanded = true;
  showSubmenu: boolean = false;
  showSubmenuJN: boolean = false;
  
  isShowing = false;
 

  //NewsLetterForm and form submit Declaration
  dataNewsLetterForm: FormGroup;
  public successModal: any = false;


  public ckeConfig: any = {
    toolbar: [
      {
        name: "editing",
        items: ["Scayt", "Find", "Replace", "SelectAll"],
      },
      {
        name: "clipboard",
        items: [
          "Cut",

        ],
      },
      {
        name: "links",
        items: [
          "Link",

        ],
      },

      { name: "document", items: ["Source"] },
      {
        name: "insert",
        items: [
          "Image",

        ],
      },
      "/",
      {
        name: "basicstyles",
        items: [
          "Bold",
          "Italic",
          "Underline",

        ],
      },


      {
        name: "styles",
        items: ["Styles", "Format", "FontSize", "-", "TextColor", "BGColor"],
      },
    ],
  };

  emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
  textregex: RegExp = /^[a-zA-Z]*$/;

  temtdata: any = '';
  // formdata
  formfieldrefresh: any = false;
  updatetable: any = false;
  formfieldrefreshdata: any = null;
  datasource: any;
  public flag: any;
  public formdata: any;
  public stateVal: any = [];
  public status: any = [
    { val: 1, name: 'Active' },
    { val: 0, name: 'Inactive' },
  ];
  public userData: any = [];
  public resolveval: any;
  public testFlag:boolean = true;
  
 

  constructor(public fb: FormBuilder, public dialog: MatDialog, public apiService: ApiService, public userservices: UserserviceService, public apiservicenew: HttpService, public snackBar: MatSnackBar,) {
    //NewsLetterForm validation
    this.dataNewsLetterForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    })


    this.apiService.getState().subscribe((res: any) => {
      for (const i in res) {
        if (Object.prototype.hasOwnProperty.call(res, i)) {
          this.stateVal.push({ val: res[i].abbreviation, name: res[i].name });
        }
      }
    });

    this.formdata = {
      successmessage: 'Added Successfully !!',
      // redirectpath: '/home',
      submitactive: true, // optional, default true
      submittext: 'Sign up',
      // resettext: 'Reset',
      // canceltext: 'Cancel',
      // apiUrl: this.apiservice.api_url,
      apiUrl: this.apiService.api_url,
      // endpoint: 'api/adddata123',
      // jwttoken: this.cookies.get('jwtToken'),
      jwttoken: this.userservices.jwttoken,



      // cancelroute: '/home',

      fields: [



        {
          label: 'Your email address',
          name: 'email',
          type: 'email',
          hint: '',

          validations: [
            { rule: 'required', message: 'Email is required' },
            {
              rule: 'pattern',
              value: this.emailregex,
              message: 'Must be a valid Email',
            },
          ],
        },


        {
          label: 'type',
          name: 'type',
          type: 'hidden',
          value: 'admin',
        },


      ],
    };
  }

  //Menu Openclose Function
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
  //NewsLetterForm Submission
  doNewsLetterSubmit() {
    // console.log(this.dataNewsLetterForm.value);
    let x: any;
    // use for validation checking
    for (x in this.dataNewsLetterForm.controls) {
      this.dataNewsLetterForm.controls[x].markAsTouched();
    }
    if (this.dataNewsLetterForm.valid) {
      // let data: any = this.dataNewsLetterForm.value;
      // let link = "";
      // this.apiService.newsLetterFormSubmit(data,link).subscribe((response:any)=>{
      // console.log("response",response);  
      // if (response.status === 'success'){
      const dialogRef = this.dialog.open(NewsLetterModalComponent, {
        panelClass: 'successModal'
      });
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(result => {
        // console.log(`Dialog result: ${result}`);
      });
      this.dataNewsLetterForm.reset();
      this.successModal = true;
      setTimeout(() => {
        // this.successModal = false;
      }, 2000);
      // }
      // });
      // console.log(data);
      // this.successModal = true;
    }
  }
  inputUntouched(val: any) {
    this.dataNewsLetterForm.controls[val].markAsUntouched();
  }

  listenFormFieldChange(val: any) {
    if (val.field == "fromsubmit" && val.fieldval == "success") {
      if (val.fromval.status == true) {
        val.fromval.status = parseInt("1");
      } else {
        val.fromval.status = parseInt("0");
      }
      let data: any = {
        "data": val.fromval,
        "collection": "newsletter"
      }
      delete data.data.status;

      this.apiservicenew.httpViaPost("api/adddata", data).subscribe(response => {
        if (response.status == "success") {
          this.snackBar.open("Added Succesfully", 'Ok', {
            duration: 2000,
          });
          // this.dialogRef.close();
          // this.router.navigateByUrl("home");
        } else {
        }
      });
    }
  }
  
  //Coming Soon Modal Function
  doComingSoon(){   
    const dialogRef = this.dialog.open(  comingSoonModalTPComponentTop,{
      panelClass:'successModal'
    });
    dialogRef.disableClose=true;
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
  
  }
  joinus(){
    console.log("join us");
    const dialogReference = this.dialog.open(JoinUsComponent, {
      panelClass:'dialogbox-adduser'
      // data: listnervalue.custombuttonclick.data,
    });

    dialogReference.afterClosed().subscribe((afterCloseuser: any) => {
      console.log("afterCloseuser==", afterCloseuser);
      if (afterCloseuser == "customer") {
        console.log("customer selector catched");
      }
      if (afterCloseuser == "tr_astro") {
        console.log("astro selector catched");
      }
    });

    
  }
  // AstrologicalTarotMenu(){
  //   this.trigger.toggleMenu();
  // }
  
  // openMyMenu() {
  //   this.trigger.toggleMenu();
  // } 
  // closeMyMenu() {
  //   this.trigger.closeMenu();
  //   console.log('close')
  // }
}



//Success Form Section
@Component({
  selector: 'app-NewsLetterConfirm',
  templateUrl: '../../common/success.html',
  styleUrls: ['./header.component.css']
})

export class NewsLetterModalComponent {

}

@Component({
  selector: "dialogbox-adduser",
  templateUrl: "joinus.html",
})
export class JoinUsComponent {
  public receiveddata: any;
  ormfieldrefresh: boolean = false;
  updatetable: boolean = false;
  formfieldrefreshdata: any = null;
  public custombuttons: any;
  public externaldatavalue: any;
  passwordregex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  public date: any = [
    { val: 1, name: "1" },
    { val: 2, name: "2" },
    { val: 3, name: "3" },
    { val: 4, name: "4" },
    { val: 5, name: "5" },
    { val: 6, name: "6" },
    { val: 7, name: "7" },
    { val: 8, name: "8" },
    { val: 9, name: "9" },
    { val: 10, name: "10" },
    { val: 11, name: "11" },
    { val: 12, name: "12" },
    { val: 13, name: "13" },
    { val: 14, name: "14" },
    { val: 15, name: "15" },
    { val: 16, name: "16" },
    { val: 17, name: "17" },
    { val: 18, name: "18" },
    { val: 19, name: "19" },
    { val: 20, name: "20" },
    { val: 21, name: "21" },
    { val: 22, name: "22" },
    { val: 23, name: "23" },
    { val: 24, name: "24" },
    { val: 25, name: "25" },
    { val: 26, name: "26" },
    { val: 27, name: "27" },
    { val: 28, name: "28" },
    { val: 29, name: "29" },
    { val: 30, name: "30" },
    { val: 31, name: "31" },
  ];
  public month: any = [
    { val: 1, name: "January" },
    { val: 2, name: "February" },
    { val: 3, name: "March" },
    { val: 4, name: "April" },
    { val: 5, name: "May" },
    { val: 6, name: "June" },
    { val: 7, name: "July" },
    { val: 8, name: "August" },
    { val: 9, name: "September" },
    { val: 10, name: "October" },
    { val: 11, name: "November" },
    { val: 12, name: "December" },
  ];

  public year: any = [];
  public countryVal: any = [];
  public allstateVal: any = [];
  public allcityval: any = [];
  public stateVal: any = [];
  public cityVal: any = [];
  public requestDataAddress: any = {
    state: "",
    city: "",
    country: "",
  };

  public geometryData: any;
  public latitude: any = "";
  public longitude: any = "";

  public resultdata: any = [];
  public formfieldrefresh:any;


  public formdata: any = {
    successmessage: "Added Successfully",
    submittext: "Submit",
    canceltext: "Cancel",
    resettext: "Reset",
    redirectpath: "admin/list",
    submitactive: true, //optional, default true
    apiUrl: this.apiservice.apiUrlNd,
    endpoint: "api1/add-or-update-user",
    jwttoken: this.cookieService.get("jwtToken"),
    secret: this.cookieService.get("secret"),
    groups: ["grp1", "grp2", "grp3","grp4","grp5"],
    fields: [
      // {
      //   label: "Relation",
      //   name: "relation",
      //   group:'grp1',
      //   value:"",
      //   type: "text",
      // },

      {
        label: "First Name",
        name: "firstname",
        group: "grp1",
        value: "",
        type: "text",
      },
      {
        label: "Last Name",
        name: "lastname",
        group: "grp1",
        value: "",
        type: "text",
      },
      {
        label: "E-mail",
        name: "email",
        group: "grp1",
        value: "",
        type: "text",
      },
      {
        label: "Phone Number",
        name: "phone",
        group: "grp2",
        value: "",
        type: "number",
        validations: [
          { rule: "required", message: "Phone Number is Required" },
        ],
      },

      {
        label: "Time of Birth ",
        name: "birth_time",
        group: "grp2",
        value: "",
        type: "timepicker",
        format: 12,
        validations: [
          {
            rule: "required",
            message: "This field is required",
          },
        ],
      },
      {
        label: "Date Of Birth",
        name: "dob",
        type: "date",
        group: "grp2",
        // value: new Date().toISOString(),
        // hint: "05/05/2020",
        minDate: new Date(1900, 12, 31),
        maxDate: new Date(),
        validations: [
          { rule: "required", message: "This field Needs to be required" },
        ],
      },

      

      {
        label: "Country",
        name: "country",
        group: "grp3",
        value: "",
        val: this.countryVal,
        type: "select",
        validations: [{ rule: "required", message: "Country is Required" }],
      },

      {
        label: "State",
        name: "state",
        group: "grp3",
        value: "",
        val: this.stateVal,
        type: "select",
        validations: [{ rule: "required", message: "State is Required" }],
      },
      {
        label: "City",
        name: "city",
        group: "grp3",
        value: "",
        val: this.cityVal,
        type: "select",
        validations: [{ rule: "required", message: "City is Required" }],
      },

      {
        label: "Latitude",
        name: "latitude",
        value: "",
        type: "hidden",
      },
      {
        label: "Longitude",
        name: "longitude",
        value: "",
        type: "hidden",
      },
      {
        label: "Password",
        name: "password",
        type: "password",
        group: "grp4",
        passwordflag: false,
        value: "",
        validations: [
          {
            rule: "required",
            message: "Password is required",
          },
          {
            rule: "pattern",
            value: this.passwordregex,
            message: "Must contain a Capital Letter and a Number",
          },
        ],
      },
      {
        label: "Confirm Password",
        name: "confirmpassword",
        type: "text",
        group: "grp4",
        passwordflag: false,
        validations: [
          {
            rule: "required",
            message: "Confirm Password is required",
          },
          {
            rule: "match",
            message: "Confirm Password field Needs to match Password",
          },
          // {rule:'pattern',value: this.passwordregex, message: "Must contain a Capital Letter and a Number"}
        ],
      },
      {
        label: "Active",
        name: "status",
        // hint: "Checkbox allows a user to be active/inactive" ,
        value: false,
        type: "checkbox",
      },
      {
        label: "Type",
        name: "type",
        value: "customer",
        type: "hidden",
      },
      
    ],
  
}
constructor(
  public dialogRef: MatDialogRef<JoinUsComponent>,
  @Inject(MAT_DIALOG_DATA) public dialogdata: DialogData,
  public apiservice: ApiService,
  public activatedroute: ActivatedRoute,
  public userservice: UserserviceService,
  private cookieService: CookieService
) {console.log("join us component");}
listenFormFieldChange(event:any){

}
}


 //Coming Soon Modal
 @Component({
  selector: 'app-comingSoon',
  templateUrl: '../../common/comingsoon.html',
  styleUrls: ['../footer/footer.component.css']
})

export class   comingSoonModalTPComponentTop {

}
