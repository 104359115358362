import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoMaterialModule } from '../material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { CKEditorModule } from 'ng2-ckeditor';


// import { BackendHeaderComponent } from 'src/app/layout/backend-header/backend-header.component';
// import { BackendFooterComponent } from 'src/app/layout/backend-footer/backend-footer.component';
import { HeaderComponent, JoinUsComponent, NewsLetterModalComponent, comingSoonModalTPComponentTop} from 'src/app/layout/header/header.component';
import { FooterComponent, comingSoonModalTPComponent, zoomLogoModalFooterComponent } from 'src/app/layout/footer/footer.component';
import { RouterModule } from '@angular/router';
import { TopcontactComponent } from 'src/app/layout/topcontact/topcontact.component';
import { ListingModule } from "listing-angular7";
import { BackendHeaderComponent } from 'src/app/layout/backend-header/backend-header.component';
import { BackendFooterComponent } from 'src/app/layout/backend-footer/backend-footer.component';




@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    JoinUsComponent,
    TopcontactComponent,
    comingSoonModalTPComponent,
    zoomLogoModalFooterComponent,
    NewsLetterModalComponent,
    comingSoonModalTPComponentTop,
    BackendHeaderComponent,
    BackendFooterComponent
    
  ],
  imports: [
    CommonModule,
    // BlockUIModule.forRoot(),
    // CommonModule,
    DemoMaterialModule,
    RouterModule,
    ListingModule,
    FormsModule,
    ReactiveFormsModule,
    // ChartsModule,
    // CalendarManagementModule,
    // CKEditorModule,

    // SatDatepickerModule
  ],
  exports:[
    BackendHeaderComponent,
    BackendFooterComponent,
    HeaderComponent,
    FooterComponent,
    DemoMaterialModule,
    TopcontactComponent,
    ListingModule,
    FormsModule,
    JoinUsComponent
  ],
  entryComponents:[
    JoinUsComponent,
    comingSoonModalTPComponent,
    zoomLogoModalFooterComponent,
    NewsLetterModalComponent,
    comingSoonModalTPComponentTop
  ],
  
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ShareModule { }
