import {
  ElementRef,
  EventEmitter,
  Injectable,
  Input,
  ViewChild,
} from '@angular/core';
import { switchMap, map, takeWhile, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Observer, Subject, Subscription, throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImagelodeserviceService {
  public imageFlag: boolean = false;
  public imgarr: any = [];
  private subject = new Subject<Boolean>();
  constructor(
    private http: HttpClient,
    private cookieservice: CookieService,
    public router: Router,
    public activatedroute: ActivatedRoute,
    private snackbar: MatSnackBar,

  ) {
    // this.observer=false;
  }


  imageFunc(data) {
    // console.log("data++", data);

    return this.imageFlag
  }
  setimageloaderflag(val: boolean) {
    this.subject.next(val);

  }
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  startloadscheck(val: any) {
    // console.log('img arr', val);
    let imgc = val.length;
    let loadedcount: any = 0;
    for (let p in val) {
      // console.log(val[p]);
      this.imgarr[p] = new Image();
     
      this.imgarr[p].src = val[p];

      this.imgarr[p].decode().then(() => {
        /* set styles */
        /* add img to DOM */
        loadedcount++;
        // console.log('img loaded', loadedcount, imgc);
        if (loadedcount == imgc)
          this.setimageloaderflag(false);
      });
    }

  }
}
