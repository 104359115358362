import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import {
  HomeComponent,
  zoomLogoModalComponent,
  applNowModalComponent,
  comingSoonModalComponent,
  applyNowSubmitComponent,

} from './home/home.component';
import { PagehomeComponent } from './pagehome/pagehome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { TopcontactComponent } from './layout/topcontact/topcontact.component';
import { ShareModule } from './modules/share/share.module';
import { DemoMaterialModule } from './modules/material-module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from 'ngx-cookie-service';
import { MyLoaderComponent } from './layout/my-loader/my-loader.component';

// import { ImagesLazyloadModule } from './modules/share/images-lazyload/images-lazyload.module';

import { ImagesLazyloadModule } from './shared/images-lazyload/images-lazyload.module';
import { ListingModule } from "listing-angular7";


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PagehomeComponent,
    MyLoaderComponent,

    zoomLogoModalComponent,
    applNowModalComponent,
    comingSoonModalComponent,
    applyNowSubmitComponent,

    

    // TopcontactComponent,

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ShareModule,
    DemoMaterialModule,
    HttpClientModule,
    ImagesLazyloadModule,
    ListingModule,
  ],
  providers: [
    CookieService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    zoomLogoModalComponent,
    applNowModalComponent,
    comingSoonModalComponent,
    applyNowSubmitComponent,
  ]
})
export class AppModule { }
